<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-black cl-main ftw500">次卡订单详情</div>

			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">

					<div class="">
						<div class="">
							<div class="ft20 ftw400 cl-black">{{order.name}}</div>
							<div class="mt20">
								<div class="flex alcenter">
									<div class="flex alcenter">
										<div class="ft14 ftw400 cl-info">订单编号：</div>
										<div class="ft14 ftw600 cl-main">{{order.member_once_card_id}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info">下单时间：</div>
										<div class="ft14 ftw600 cl-main">{{order.add_time_format}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info text-over4">有效期至：</div>
										<div class="ft14 ftw600 cl-theme text-over4">{{order.valid_end_time}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="project-once-card-order-detail-info">
							<div class="flex ">
								<div class="pl40" style="width: 33%;border-right: 1px solid #EBEDF5;">
									<div class="ft14 ftw500 cl-main">订单状态</div>
									<div class="ft20 ftw600 cl-theme mt16" >{{order.status_means}}</div>
								</div>
								<div class="" style="width: 33%;">
									<div class="flex" style="border-right: 1px solid #EBEDF5; ">
										<div class="ml40 text-over4">
											<div class="ft14 ftw500 cl-main text-over4">付款信息</div>
											<div class="mt16">
												<div class="flex">
													<div>
														<div class="flex alcenter ">
															<div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
															<div class="ft14 ftw600 cl-main text-over4" >
																{{order.pay_type_mean}}
															</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 ftw400 cl-info text-over4">支付时间：</div>
															<div class="ft14 ftw600 cl-main text-over4" >
																{{order.pay_time_format}}
															</div>
														</div>
														
														<div class="flex  mt10">
															<div class="ft14 ftw400 cl-info text-over4">实付金额：</div>
															<div class="ft14 ftw600 cl-main text-over4">
																¥{{order.need_pay}}</div>
															<div class="ml5 ft12 ftw400" style="color: #A6AEC2;">
																<div class="flex alcenter">
																	<div>原价：</div>
																	<div class="text-line">
																		¥{{order.total_price}}
																	</div>
																</div>
																<div v-if="order.coupon_money>0">优惠券抵扣：-¥{{order.coupon_money}}</div>
																<div v-if="order.integral_balance>0">积分抵扣：-¥{{order.integral_balance}}</div>
															</div>	
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="" style="width: 33%;">
									<div class="ml40">
										<div class="ft14 ftw500 cl-main">客户信息</div>
										<div class="mt16">
											<div class="flex">
												<div>
													<div class="flex alcenter">
														<div class="ft14 ftw400 cl-info text-over4">客户姓名：</div>
														<div class="ft14 ftw600 cl-main text-over4">
															{{order.member_name}}</div>
													</div>
													<div class="flex alcenter mt10">
														<div class="ft14 ftw400 cl-info text-over4">手机号码：</div>
														<div class="ft14 ftw600 cl-main text-over4">
															{{order.member_mobile}}</div>
													</div>
													</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


					<!-- <div class="mt30" v-if="order.items.length>0">
						<div class=" flex wrap">
							<div class="project-once-card-order-detail-items"  v-for="(item,index) in order.items">
								<div class="flex space alcenter" style="width: 100%;">
									<div class="flex alcenter">
										<img :src="item.cover_img" class="project-once-card-order-detail-items-img"/>
										<div class="ml10">
											<div class="text-over4 ft14 ftw600 cl-black">{{item.abbreviation}}</div>
											<div class="ft12 ftw400 cl-placeholder mt5">门市价：¥{{item.market_price}}</div>
											<div class="flex alcenter mt5">
												<div class="flex alcenter">
													<div class="ft12 ftw400 cl-notice">总次数</div>
													<div class="ft14 ftw600 cl-black ml5">{{item.total_num}}次</div>
												</div>
												<div class="flex alcenter ml10">
													<div class="ft12 ftw400 cl-notice">剩余次数</div>
													<div class="ft14 ftw600 cl-deepblue ml5">{{item.remain_num}}次</div>
												</div>
											</div>
										</div>
									</div>
									
									<div class="flex alcenter ">
										<div class="button-write-off " v-if="item.remain_num>0 && order.status_another==1" @click="writeOffAct(index)">核销</div>
										<div class="button-write-off-over " v-if="item.remain_num<=0 && order.status_another==1" >核销完</div>
										<div class="button-write-off-over " v-if="order.status_another==8" >已完成</div>
										<div class="button-write-off-over "  v-if="order.status_another==-1">已过期</div>
									</div>
								</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		
			<div v-if="writeOffVisible">
				<write-off :datas="item" :visible="writeOffVisible" @cancel="cancelWriteOff" @ok="okWriteOff"></write-off>
			</div>
		</a-spin>
	</div>

</template>

<script>
	import writeOff from './components/OrderOncecardDetail/modal/writeOff.vue';
	export default {
		components:{
			writeOff,
		},
		data() {
			return {
				loading: false,
				writeOffVisible:false,
				member_once_card_id: 0,
				item:{},
				order: {
					member_once_card_id: 0,
					add_time_format: '',
					valid_end_time: '',
					status: 1,
					status_means: '待消费',
					need_pay: 0,
					pay_type_mean: '',
					pay_time_format: '',
					total_price: 0,
					coupon_money: 0,
					integral_balance: 0,
					member_name: '',
					member_mobile: '',
					items: []
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.member_once_card_id = 0;
			} else {
				this.member_once_card_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		methods: {
			loaddata() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getOncecardOrderDetail', {
					member_once_card_id: this.member_once_card_id
				}).then(res => {
					this.order = res.detail;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
					this.$router.go(-1);
				})
			},
			
			writeOffAct(index){
				this.item=this.order.items[index];
				this.writeOffVisible=true;
			},
			cancelWriteOff(){
				this.writeOffVisible=false;
			},
			okWriteOff(){
				this.writeOffVisible=false;
				this.loaddata();
			},
			
		}
	}
</script>

<style>
	.project-once-card-order-detail-tag {
		padding: 1px 8px;
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}

	.project-once-card-order-detail.tag {
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}


	.project-once-card-order-detail-info {
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		padding: 24px 0px;
	}

	.project-once-card-order-detail-subcard-item {
		width: 100%;
		height: 100px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		margin-bottom: 20px;
		padding: 20px;
	}

	.project-once-card-order-detail-subcard-item img {
		width: 75px;
		height: 60px;
	}

	.button-deliver {
		width: 96px;
		height: 40px;
		background: #4772FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}
	
	@media screen and (max-width: 1279px) {
		.project-once-card-order-detail-items{
			width: 100%;
			background: #FFFFFF;
			border: 1px solid #F0F3F5;
			padding: 20px;
			margin-bottom: 8px;
			margin-right: 8px;
		}
	}
	
	
	@media screen and (min-width:1280px){
		.project-once-card-order-detail-items{
			width: 48%;
			background: #FFFFFF;
			border: 1px solid #F0F3F5;
			padding: 20px;
			margin-bottom: 8px;
			margin-right: 8px;
		}
	}
	
	.project-once-card-order-detail-items-img{
		width: 100px;
		height: 80px;
	}
</style>
